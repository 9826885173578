module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://backend.mill-all.com/graphql","develop":{"hardCacheData":true,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"debug":{"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5,"timeout":6000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"Post":{"limit":null},"Article":{"limit":null},"Category":{"limit":null},"Session":{"limit":null},"Eventregistration":{"limit":null},"Speaker":{"limit":null},"MediaItem":{"localFile":{"maxFileSizeBytes":10485760,"requestConcurrency":100,"excludeByMimeTypes":[]},"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter WordPress Blog","short_name":"GatsbyJS & WP","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/explore-ma-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0f5902b5122e0cc6b5cf7efbc1694e76"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
