// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// custom CSS styles
import "./src/css/style.css"
import "./src/css/general.css"
import "./static/utm_form-1.2.0.js"

import React from "react";

export const onClientEntry = () => {
  if (typeof window !== "undefined" && !document.getElementById("hs-script-loader")) {
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/46102677.js";
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    document.head.appendChild(script);
  }
};